function showCookieBanner() {
  $('#cookieConsentBanner').fadeIn(500);
}

function hideCookieBanner() {
  $('#cookieConsentBanner').hide();
}

function getCookiesConsent() {
  let consent = getCookie("ss-consent");
  return consent;
}

function initCookieBanner(translations) {
  if (getCookiesConsent()) return;
  if (translations['cookie-consent.text']) {
    $('.cookie-consent-text').html(translations['cookie-consent.text'])
  }
  if (translations['cookie-consent-i-understand.button']) {
    $('.cookie-consent-btn').html(translations['cookie-consent-i-understand.button'])
  }
  showCookieBanner()
}

$('#acceptCookies').on('click', function () {
  setCookie('ss-consent', "1; secure");
  $('#cookieConsentBanner').fadeOut(500);
})